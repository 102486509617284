import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { Post } from '../post/post-component';
import GraphicType from './graphic-types';

export default function Graphic({ posts }: GraphicType) {
  const [currentPostIndex, setCurrentPostIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPostIndex(previous =>
        previous === posts.length - 1 ? 0 : previous + 1,
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [posts.length]);

  if (posts.length === 0) {
    return null;
  }

  const currentPost = posts[currentPostIndex];

  return (
    <div className="relative w-full overflow-y-hidden overflow-x-clip pb-0 pl-24 pr-8 pt-0 md:w-full md:pl-32 md:pt-32">
      <div className="border-grey-600 w-full rounded-lg border-2">
        <div className="border-grey-600 border-b-2 p-4 pb-2">
          <p className="font-semibold uppercase text-primary">Inbox</p>
          <div className="mt-2 flex gap-4">
            <div className="relative h-2 w-16 rounded-full bg-gray-300 after:absolute after:-bottom-2 after:left-0 after:h-1 after:w-full after:rounded-t-full after:bg-primary after:content-['']" />
            <div className="h-2 w-16 rounded-full bg-gray-300" />
            <div className="h-2 w-16 rounded-full bg-gray-300" />
          </div>
        </div>
        <div className="divide-grey-600 w-full divide-y">
          {posts.map((post, index) => (
            <div key={index}>
              <div className="px-4">
                <Link
                  href={post.link}
                  className="border-grey-600 flex animate-mail1 gap-4 rounded-lg border bg-white px-4 py-3 shadow-lg"
                  data-x-position="-20"
                  style={{ opacity: '1', transform: 'translate(-40px, 0px)' }}
                >
                  <svg
                    width="38"
                    viewBox="0 0 38 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x=".5"
                      y="2.5"
                      width="11"
                      height="11"
                      rx="3.5"
                      stroke="#C9CCCF"
                    ></rect>
                    <path
                      d="m36.667 6.16-4.793-.413-1.873-4.414-1.874 4.42-4.793.407 3.64 3.153L25.881 14 30 11.513 34.12 14l-1.087-4.687 3.633-3.153Z"
                      fill="#EFA7A2"
                    ></path>
                  </svg>
                  <span
                    className={index === currentPostIndex ? 'font-bold' : ''}
                  >
                    🥤 {post.title}{' '}
                  </span>
                </Link>
              </div>
              <div className="px-4 py-3">
                <svg
                  width="228"
                  viewBox="0 0 228 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x=".5"
                    y="2.5"
                    width="11"
                    height="11"
                    rx="3.5"
                    stroke="#C9CCCF"
                  ></rect>
                  <path
                    d="m36.667 6.16-4.793-.413-1.873-4.414-1.874 4.42-4.793.407 3.64 3.153L25.881 14 30 11.513 34.12 14l-1.087-4.687 3.633-3.153Zm-6.666 4.107-2.507 1.513.667-2.853-2.214-1.92 2.92-.254 1.134-2.686 1.14 2.693 2.92.253-2.214 1.92.667 2.854-2.513-1.52Z"
                    fill="#C9CCCF"
                  ></path>
                  <rect
                    x="48"
                    y="2"
                    width="180"
                    height="12"
                    rx="6"
                    fill="#EAF0F6"
                  ></rect>
                </svg>
              </div>
            </div>
          ))}
        </div>

        <div className="absolute right-16 top-16 hidden w-[250px] rotate-[10deg] animate-image md:block">
          <div className="relative">
            <Image
              src="/graphic.png"
              alt="phone"
              width={250}
              height={493}
              className="absolute left-0 top-0 z-40"
            />
            <div className="z-50 w-full bg-white">
              <Post
                title={currentPost.title}
                img={currentPost.img}
                link={currentPost.link}
                variant="homepage_graphic"
                description={currentPost.description}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
