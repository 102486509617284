import { captureException } from '@sentry/nextjs';
import DailyDesignLogo from 'assets/icons/daily-design-logo.png';
import Reactsquadlogo from 'assets/icons/React-squad-logo.png';
import Releaselog from 'assets/icons/release-log.png';
import { AxiosError } from 'axios';
import LayoutComponent from 'components/layout/layout-component';
import { Post as PostComponent } from 'components/post/post-component';
import { createPost } from 'components/post/post-helpers';
import { PostType } from 'components/post/post-types';
import { ProductFeature } from 'components/product-feature/product-feature-component';
import ReactSquadModalAd from 'components/reactsquadad/react-squad-modal-ad';
import { Typography } from 'components/typography/typography-component';
import { htmlToText } from 'html-to-text';
import { getPosts, getPostsByTag } from 'lib/db/post';
import { stripHtmlContent } from 'lib/utils/strip-html';
import type { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';

import Graphic from '../components/graphic/index';
import NewsletterHome from '../components/newsletter/newsletter-home';

const Homepage = ({
  lastestPosts,
  homepageGraphicPosts,
  featuredPosts,
}: {
  lastestPosts: PostType[];
  homepageGraphicPosts: PostType[];
  featuredPosts: PostType[];
}) => {
  const [firstFeaturedPost, ...restOfFeaturedPost] = featuredPosts;

  return (
    <LayoutComponent>
      <NextSeo
        title="EarlyNode – Start, build and grow your SaaS"
        description="Everything SaaS founders need to know to start, build and grow their companies. Guides, insights, advice from SaaS veterans."
        openGraph={{
          url: process.env.NEXT_PUBLIC_SITE_URL,
          title: 'EarlyNode – Start, build and grow your SaaS',
          description:
            'Everything SaaS founders need to know to start, build and grow their companies. Guides, insights, advice from SaaS veterans.',
        }}
        canonical={process.env.NEXT_PUBLIC_SITE_URL}
      />
      <div className="mx-auto px-4 md:container sm:w-full md:px-5">
        <div className="mb-12">
          <div className="mb-12 flex flex-row items-center">
            <div className="lg:w-2/3">
              <NewsletterHome handleCloseModal={() => {}} />
            </div>
            <div className="hidden w-full items-center justify-end md:block lg:col-span-2">
              <Graphic posts={homepageGraphicPosts} />
            </div>
          </div>
          <div className="flex flex-col gap-4 md:flex-row">
            <div className="w-full md:w-2/3 md:pr-4">
              <div className=" w-full">
                <Typography
                  variant="h2"
                  className="mb-6 border-b-2 border-black text-[2rem]"
                >
                  Featured Stories
                </Typography>
                {firstFeaturedPost && (
                  <PostComponent
                    variant="featured"
                    title={firstFeaturedPost?.title || ''}
                    description={htmlToText(
                      stripHtmlContent(firstFeaturedPost.description ?? ''),
                    )}
                    img={firstFeaturedPost?.img}
                    link={firstFeaturedPost.link}
                  />
                )}

                <div className="mt-8 flex flex-row flex-wrap gap-[4%] gap-y-6">
                  {restOfFeaturedPost?.map((post, index) => (
                    <div key={index} className="mb-8 w-full sm:w-[48%]">
                      <PostComponent
                        count={`0${index + 1}`}
                        variant="count"
                        title={post?.title || ''}
                        description={htmlToText(
                          stripHtmlContent(post?.description ?? ''),
                        )}
                        img={post?.img || ''}
                        link={post.link}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="box-border w-full border-slate-50 md:w-1/3 md:border-l-2 md:pl-8">
              <Typography
                variant="h2"
                className="mb-6 border-b-2 border-black text-[2rem]"
              >
                Latest Articles
              </Typography>
              <div className="grid grid-cols-1 gap-4 gap-y-2 xsm:grid-cols-1 sm:grid-cols-2 md:grid-cols-1">
                {lastestPosts?.map((post, index) => (
                  <div className="mb-8" key={index}>
                    <PostComponent {...post} key={index} variant="sidebar" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-12 py-6">
          <ProductFeature
            imageUrl={Reactsquadlogo as unknown as string}
            link="/react-squad"
            productName="ReactSquad"
            title="Providing React developers to fast-growing startups in < 7 days."
            description="Supporting SaaS companies using React by shipping new products & features as an extension of their in-house engineering team."
          />
          <ProductFeature
            imageUrl={Releaselog as unknown as string}
            link="/releaselog"
            productName="Releaselog.io"
            title="The changelog tool for SaaS"
            description="Easiest way to keep your customers in the loop about your product."
          />
        </div>
      </div>
      <ReactSquadModalAd />
    </LayoutComponent>
  );
};

// This is a temporary fix for the error we're getting on the homepage.
// The API seems to be NOT returning an array, but an object and therefore,
// .map doesn't work.
const getMaybeArray = (maybeArray: any[]) =>
  Array.isArray(maybeArray) ? maybeArray : [];

export const getStaticProps: GetStaticProps = async () => {
  // This shouldn't stay in the code base, we should remove it at some point,
  // once we figure out what's going wrong.
  const debugPostData: any = {};

  try {
    const featuredPosts = await getPostsByTag({
      tag: 'featured-home',
      limit: 5,
    });
    debugPostData['featuredPosts'] = featuredPosts;

    const homepageGraphicPosts = await getPostsByTag({
      tag: 'homepage-graphic',
      limit: 5,
    });
    debugPostData['homepageGraphicPosts'] = homepageGraphicPosts;

    const lastestPosts = await getPosts();
    debugPostData['lastestPosts'] = lastestPosts;

    return {
      props: {
        lastestPosts: lastestPosts?.map(createPost) ?? [],
        homepageGraphicPosts: homepageGraphicPosts?.map(createPost) ?? [],
        featuredPosts: featuredPosts?.map(createPost) ?? [],
      },
      revalidate: 600,
    };
  } catch (error) {
    captureException(error, {
      extra: {
        component: 'getStaticProps',
        path: 'src/pages/index.tsx',
        debugPostData,
        apiError: error instanceof AxiosError ? error?.response?.data : false,
      },
    });

    return {
      props: {
        lastestPosts: getMaybeArray(debugPostData.getLatestPosts),
        homepageGraphicPosts: getMaybeArray(
          debugPostData.getHomepageGraphicPosts,
        ),
        featuredPosts: getMaybeArray(debugPostData.getFeaturedPosts),
      },
    };
  }
};

export default Homepage;
