import axios, { AxiosError } from 'axios';
import { Formik } from 'formik';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import MailIcon from '../../assets/icons/mail.svg';
import { Button } from '../button/button-component';
import { Typography } from '../typography/typography-component';
import NewsletterModal from './newsletter-modal';
import { HandleInitialSubmit, InitialFormProps } from './types';

const NewsletterHome = ({
  handleCloseModal,
}: {
  handleCloseModal: (id: number) => void;
  formId?: string;
}) => {
  const [error, setError] = useState<string>('');
  const [modalId, setModalId] = useState<number | null>(null);

  const handleSubmit: HandleInitialSubmit = async (
    values,
    { setSubmitting, resetForm },
  ) => {
    setSubmitting(true);
    try {
      const { data } = await axios.post('/api/beehiiv/subscribe', {
        email: values.email,
      });
      resetForm();
      setModalId(data.subscriber.id);
      handleCloseModal?.(data.subscriber.id);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const initialValue: InitialFormProps = {
    email: '',
  };

  useEffect(() => {
    let errorTimeout: NodeJS.Timeout;
    if (error) {
      errorTimeout = setTimeout(() => {
        setError('');
      }, 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
    };
  }, [error]);

  return (
    <div className="py-20">
      <Typography variant="h1" className="text-4xl font-bold lg:font-[48px]">
        Get actionable insights from SaaS veterans to grow your MRR.
      </Typography>
      <Typography variant="lead" className="my-4 mb-6 text-lg font-extrabold">
        Join hundreds of SaaS founders:
      </Typography>
      <Formik initialValues={initialValue} onSubmit={handleSubmit}>
        {({ values, handleChange, isSubmitting, handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            className="flex-2 rounded-xl shadow-[0px_0px_4px_3px_rgba(0,0,0,0.03)] lg:pl-4"
          >
            <div className="sm:flex-end m-0 w-auto rounded-xl border-8 border-white sm:relative sm:flex sm:items-center sm:justify-between sm:border-none sm:py-0.5 sm:pr-2.5">
              <div className="flex w-full items-center">
                <div className="pointer-events-none pl-4 sm:mt-0 sm:pl-4 lg:pl-0">
                  <MailIcon />
                </div>
                <input
                  type="email"
                  id="email-address"
                  required
                  aria-label="Email address"
                  placeholder="Your email address"
                  value={values.email}
                  onChange={handleChange}
                  name="email"
                  className="peer relative mx-2 overflow-x-auto truncate rounded-xl border-none px-2 py-3 text-base placeholder:text-slate-400 focus:border-transparent focus:outline-none focus:ring-0 sm:w-full"
                />
              </div>

              <Button
                size="md"
                type="submit"
                fullwidth
                disabled={isSubmitting}
                className="mx-3 my-2 h-[45px] w-11/12 rounded-xl sm:z-10 sm:mx-0 sm:w-auto sm:flex-none"
              >
                {isSubmitting ? 'Wait...' : 'Join Now'}
              </Button>
            </div>
            {error && (
              <Typography
                variant="paragraph"
                className="mb-4 mt-2 w-full text-sm text-red-300"
              >
                {error}
              </Typography>
            )}
          </form>
        )}
      </Formik>
      <Typography variant="lead" className="my-2 mb-4 text-sm">
        5-Minute Read. Every Tuesday. For Free.
      </Typography>
      <Link
        href="https://earlynode.com/newsletters"
        className="rounded-2xl border border-gray-300 px-5 py-2 text-xs text-gray-300"
      >
        {' See Past Issues'}
      </Link>

      {modalId && (
        <NewsletterModal open={!!modalId} onClose={() => setModalId(null)} />
      )}
    </div>
  );
};

export default NewsletterHome;
