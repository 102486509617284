import classNames from 'classnames';
import { Button } from 'components/button/button-component';
import { Typography } from 'components/typography/typography-component';
import Image from 'next/image';

export const ProductSmallFeature = ({
  productName,
  imageUrl = '',
  title,
  description,
  link,
  isSticky,
  ctaTitle,
}: {
  productName: string;
  imageUrl: string;
  title: string;
  link: string;
  description: string;
  isSticky?: boolean;
  ctaTitle?: string;
}) => {
  return (
    <section
      className={classNames(
        isSticky && 'sticky top-0',
        'mb-5 flex w-full flex-col overflow-hidden rounded-3xl bg-white p-6 shadow-[0px_0px_4px_3px_rgba(0,0,0,0.03)]',
      )}
    >
      <div className="">
        <div className="mb-5 flex flex-row items-center">
          <Image
            src={imageUrl}
            alt="Product Image"
            className="h-20 w-20 rounded-lg bg-slate-400 object-cover"
          />
          <div className="ml-5 lg:ml-7">
            <Typography variant="h3">{productName}</Typography>
            <span className="mt-3 inline-flex items-center rounded-lg bg-blue-50 px-4 py-2 text-sm font-medium text-primary">
              Service
            </span>
          </div>
        </div>
        <a href={link} rel="noreferrer">
          <Button
            className="hidden flex-row rounded-2xl py-4"
            color="blue"
            variant="filled"
            fullwidth
          >
            <span className="mr-2">Visit Website</span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9665 15.5953C13.9313 15.5604 13.8837 15.5408 13.8341 15.5408C13.7845 15.5408 13.7369 15.5604 13.7017 15.5953L10.9782 18.3187C9.71729 19.5796 7.58916 19.7132 6.19697 18.3187C4.80244 16.9242 4.93604 14.7984 6.19697 13.5375L8.92041 10.814C8.99307 10.7414 8.99307 10.6218 8.92041 10.5492L7.9876 9.61636C7.95236 9.58146 7.90477 9.56189 7.85518 9.56189C7.80558 9.56189 7.75799 9.58146 7.72275 9.61636L4.99932 12.3398C3.0165 14.3226 3.0165 17.5312 4.99932 19.5117C6.98213 21.4921 10.1907 21.4945 12.1712 19.5117L14.8946 16.7882C14.9673 16.7156 14.9673 16.596 14.8946 16.5234L13.9665 15.5953ZM20.0274 4.48589C18.0446 2.50308 14.836 2.50308 12.8556 4.48589L10.1298 7.20933C10.0949 7.24457 10.0753 7.29216 10.0753 7.34175C10.0753 7.39134 10.0949 7.43893 10.1298 7.47417L11.0603 8.40464C11.1329 8.4773 11.2524 8.4773 11.3251 8.40464L14.0485 5.6812C15.3095 4.42026 17.4376 4.28667 18.8298 5.6812C20.2243 7.07573 20.0907 9.20151 18.8298 10.4625L16.1063 13.1859C16.0715 13.2211 16.0519 13.2687 16.0519 13.3183C16.0519 13.3679 16.0715 13.4155 16.1063 13.4507L17.0392 14.3835C17.1118 14.4562 17.2313 14.4562 17.304 14.3835L20.0274 11.6601C22.0079 9.6773 22.0079 6.4687 20.0274 4.48589V4.48589ZM14.8126 8.72573C14.7774 8.69084 14.7298 8.67126 14.6802 8.67126C14.6306 8.67126 14.583 8.69084 14.5478 8.72573L9.23916 14.032C9.20427 14.0672 9.18469 14.1148 9.18469 14.1644C9.18469 14.214 9.20427 14.2616 9.23916 14.2968L10.1673 15.225C10.2399 15.2976 10.3595 15.2976 10.4321 15.225L15.7384 9.9187C15.811 9.84605 15.811 9.72651 15.7384 9.65386L14.8126 8.72573Z"
                fill="white"
              />
            </svg>
          </Button>
        </a>
      </div>
      <div className="flex flex-col">
        <Typography className="" variant="h3">
          {title}
        </Typography>
        <p className=" my-3 mt-2 text-sm text-gray-600 ">{description}</p>
        <a href={link} rel="noreferrer">
          <Button
            className="no-wrap mt-6 flex flex-row rounded-xl py-3"
            color="blue"
            variant="filled"
            fullwidth
          >
            <span className="mr-2">{ctaTitle || 'Visit Website'}</span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9665 15.5953C13.9313 15.5604 13.8837 15.5408 13.8341 15.5408C13.7845 15.5408 13.7369 15.5604 13.7017 15.5953L10.9782 18.3187C9.71729 19.5796 7.58916 19.7132 6.19697 18.3187C4.80244 16.9242 4.93604 14.7984 6.19697 13.5375L8.92041 10.814C8.99307 10.7414 8.99307 10.6218 8.92041 10.5492L7.9876 9.61636C7.95236 9.58146 7.90477 9.56189 7.85518 9.56189C7.80558 9.56189 7.75799 9.58146 7.72275 9.61636L4.99932 12.3398C3.0165 14.3226 3.0165 17.5312 4.99932 19.5117C6.98213 21.4921 10.1907 21.4945 12.1712 19.5117L14.8946 16.7882C14.9673 16.7156 14.9673 16.596 14.8946 16.5234L13.9665 15.5953ZM20.0274 4.48589C18.0446 2.50308 14.836 2.50308 12.8556 4.48589L10.1298 7.20933C10.0949 7.24457 10.0753 7.29216 10.0753 7.34175C10.0753 7.39134 10.0949 7.43893 10.1298 7.47417L11.0603 8.40464C11.1329 8.4773 11.2524 8.4773 11.3251 8.40464L14.0485 5.6812C15.3095 4.42026 17.4376 4.28667 18.8298 5.6812C20.2243 7.07573 20.0907 9.20151 18.8298 10.4625L16.1063 13.1859C16.0715 13.2211 16.0519 13.2687 16.0519 13.3183C16.0519 13.3679 16.0715 13.4155 16.1063 13.4507L17.0392 14.3835C17.1118 14.4562 17.2313 14.4562 17.304 14.3835L20.0274 11.6601C22.0079 9.6773 22.0079 6.4687 20.0274 4.48589V4.48589ZM14.8126 8.72573C14.7774 8.69084 14.7298 8.67126 14.6802 8.67126C14.6306 8.67126 14.583 8.69084 14.5478 8.72573L9.23916 14.032C9.20427 14.0672 9.18469 14.1148 9.18469 14.1644C9.18469 14.214 9.20427 14.2616 9.23916 14.2968L10.1673 15.225C10.2399 15.2976 10.3595 15.2976 10.4321 15.225L15.7384 9.9187C15.811 9.84605 15.811 9.72651 15.7384 9.65386L14.8126 8.72573Z"
                fill="white"
              />
            </svg>
          </Button>
        </a>
      </div>
    </section>
  );
};

export const ProductFeature = ({
  productName,
  imageUrl = '',
  title,
  description,
  link,
}: {
  productName: string;
  imageUrl: string;
  title: string;
  description: string;
  link: string;
}) => {
  return (
    <section className="mb-5 flex w-full flex-col overflow-hidden rounded-3xl bg-white p-6 shadow-[0px_0px_4px_3px_rgba(0,0,0,0.03)] lg:flex-row lg:p-4">
      <div className="lg:w-2/5 lg:p-10">
        <div className="mb-5 flex flex-row items-center lg:mb-16">
          <Image
            src={imageUrl}
            alt="Product Image"
            width={100}
            height={100}
            className="h-20 w-20 rounded-lg bg-slate-400 object-cover lg:h-24 lg:w-24"
          />
          <div className="ml-5 lg:ml-7">
            <Typography variant="h3">{productName}</Typography>
          </div>
        </div>
        <a href={link} rel="noreferrer">
          <Button
            className="hidden flex-row rounded-2xl py-4 lg:flex"
            color="blue"
            variant="filled"
            fullwidth
          >
            <span className="mr-2">Visit Website</span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9665 15.5953C13.9313 15.5604 13.8837 15.5408 13.8341 15.5408C13.7845 15.5408 13.7369 15.5604 13.7017 15.5953L10.9782 18.3187C9.71729 19.5796 7.58916 19.7132 6.19697 18.3187C4.80244 16.9242 4.93604 14.7984 6.19697 13.5375L8.92041 10.814C8.99307 10.7414 8.99307 10.6218 8.92041 10.5492L7.9876 9.61636C7.95236 9.58146 7.90477 9.56189 7.85518 9.56189C7.80558 9.56189 7.75799 9.58146 7.72275 9.61636L4.99932 12.3398C3.0165 14.3226 3.0165 17.5312 4.99932 19.5117C6.98213 21.4921 10.1907 21.4945 12.1712 19.5117L14.8946 16.7882C14.9673 16.7156 14.9673 16.596 14.8946 16.5234L13.9665 15.5953ZM20.0274 4.48589C18.0446 2.50308 14.836 2.50308 12.8556 4.48589L10.1298 7.20933C10.0949 7.24457 10.0753 7.29216 10.0753 7.34175C10.0753 7.39134 10.0949 7.43893 10.1298 7.47417L11.0603 8.40464C11.1329 8.4773 11.2524 8.4773 11.3251 8.40464L14.0485 5.6812C15.3095 4.42026 17.4376 4.28667 18.8298 5.6812C20.2243 7.07573 20.0907 9.20151 18.8298 10.4625L16.1063 13.1859C16.0715 13.2211 16.0519 13.2687 16.0519 13.3183C16.0519 13.3679 16.0715 13.4155 16.1063 13.4507L17.0392 14.3835C17.1118 14.4562 17.2313 14.4562 17.304 14.3835L20.0274 11.6601C22.0079 9.6773 22.0079 6.4687 20.0274 4.48589V4.48589ZM14.8126 8.72573C14.7774 8.69084 14.7298 8.67126 14.6802 8.67126C14.6306 8.67126 14.583 8.69084 14.5478 8.72573L9.23916 14.032C9.20427 14.0672 9.18469 14.1148 9.18469 14.1644C9.18469 14.214 9.20427 14.2616 9.23916 14.2968L10.1673 15.225C10.2399 15.2976 10.3595 15.2976 10.4321 15.225L15.7384 9.9187C15.811 9.84605 15.811 9.72651 15.7384 9.65386L14.8126 8.72573Z"
                fill="white"
              />
            </svg>
          </Button>
        </a>
      </div>
      <div className="flex flex-col lg:w-3/5 lg:items-center lg:justify-center">
        <Typography className="lg:mb-5 lg:w-3/5" variant="h3">
          {title}
        </Typography>
        <p className="lg:text-md my-3 mt-2 text-sm text-gray-600 lg:w-3/5">
          {description}
        </p>
        <a href={link} rel="noreferrer">
          <Button
            className="mt-6 flex flex-row rounded-xl py-3 lg:hidden"
            color="blue"
            variant="filled"
            fullwidth
          >
            <span className="mr-2">Visit Website</span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9665 15.5953C13.9313 15.5604 13.8837 15.5408 13.8341 15.5408C13.7845 15.5408 13.7369 15.5604 13.7017 15.5953L10.9782 18.3187C9.71729 19.5796 7.58916 19.7132 6.19697 18.3187C4.80244 16.9242 4.93604 14.7984 6.19697 13.5375L8.92041 10.814C8.99307 10.7414 8.99307 10.6218 8.92041 10.5492L7.9876 9.61636C7.95236 9.58146 7.90477 9.56189 7.85518 9.56189C7.80558 9.56189 7.75799 9.58146 7.72275 9.61636L4.99932 12.3398C3.0165 14.3226 3.0165 17.5312 4.99932 19.5117C6.98213 21.4921 10.1907 21.4945 12.1712 19.5117L14.8946 16.7882C14.9673 16.7156 14.9673 16.596 14.8946 16.5234L13.9665 15.5953ZM20.0274 4.48589C18.0446 2.50308 14.836 2.50308 12.8556 4.48589L10.1298 7.20933C10.0949 7.24457 10.0753 7.29216 10.0753 7.34175C10.0753 7.39134 10.0949 7.43893 10.1298 7.47417L11.0603 8.40464C11.1329 8.4773 11.2524 8.4773 11.3251 8.40464L14.0485 5.6812C15.3095 4.42026 17.4376 4.28667 18.8298 5.6812C20.2243 7.07573 20.0907 9.20151 18.8298 10.4625L16.1063 13.1859C16.0715 13.2211 16.0519 13.2687 16.0519 13.3183C16.0519 13.3679 16.0715 13.4155 16.1063 13.4507L17.0392 14.3835C17.1118 14.4562 17.2313 14.4562 17.304 14.3835L20.0274 11.6601C22.0079 9.6773 22.0079 6.4687 20.0274 4.48589V4.48589ZM14.8126 8.72573C14.7774 8.69084 14.7298 8.67126 14.6802 8.67126C14.6306 8.67126 14.583 8.69084 14.5478 8.72573L9.23916 14.032C9.20427 14.0672 9.18469 14.1148 9.18469 14.1644C9.18469 14.214 9.20427 14.2616 9.23916 14.2968L10.1673 15.225C10.2399 15.2976 10.3595 15.2976 10.4321 15.225L15.7384 9.9187C15.811 9.84605 15.811 9.72651 15.7384 9.65386L14.8126 8.72573Z"
                fill="white"
              />
            </svg>
          </Button>
        </a>
      </div>
    </section>
  );
};
